<template>
    <div class="side-bar-menu">
        <div class="text-center">
        <img style="width:100px;" :src="absoluteUrl(activeUser.image)" class="rounded-circle user-photo img-thumbnail" alt="profile-image" />
        <h4 class="mt-3 text-white mb-0">{{activeUser.first_name}} {{activeUser.last_name}}</h4>
        <p class="text-white small text-opacity-50">{{activeUser.email}}</p>
        </div>
        <ul class="list-group list-group-flush">
            <li class="list-group-item bg-transparent"> <router-link class="text-white" to="/"> <i class="fa fa-tachometer-alt me-1"></i> Dashboard</router-link></li>
            <li class="list-group-item bg-transparent"> <router-link class="text-white" to="/orders">
                <i class="fa fa-tasks me-1"></i> Orders</router-link>
            </li>
            <li class="list-group-item bg-transparent"> <router-link class="text-white" to="/notifications"><i class="fa fa-bell me-1"></i> Notifications</router-link></li>
            <li class="list-group-item bg-transparent"> <router-link class="text-white" to="/account"><i class="fa fa-cog me-1"></i> Settings</router-link></li>
            <li class="list-group-item bg-transparent"> <router-link @click.prevent="logout()" class="text-white" to="#"><i class="bi-power me-1"></i> Logout</router-link></li>
        </ul>
    </div>
</template>
<script>
export default {
    name:"side-bar-menu",
    computed: {
        activeUser(){
            return this.$store.state.activeUser
        },
    },
    methods:{
        logout() {
            this.$store.dispatch("changeLoaderValue",true)
            this.$store.dispatch("logoutUser")
        },
    }
}
</script>