<template>
<div class="left-side-bar">
    <b-sidebar
      id="sidebar-backdrop"
      backdrop-variant="dark"
      body-class="bg-black"
      backdrop
      no-header
      shadow
    >
    <template #default="{ hide }">
        <div class="side-bar-content p-4">
            <div class="d-flex mb-5 align-items-center justify-content-between">
                <h3 class="text-white mb-0 text-uppercase">{{$store.state.appName}}</h3>
                <button @click="hide" class="border-0 pe-0 bg-transparent text-white rounded-circle btn-sm btn-light">X</button>
            </div>
            <side-bar-menu />
        </div>
    </template>
</b-sidebar>
</div>
</template>

<script>
import SideBarMenu from './components/SideBarMenu.vue'

export default {
    components:{
        SideBarMenu
    }
}
</script>
<style >
.b-sidebar{
    width:260px;
}
</style>
