
<template>
<div>
  <div>
    <app-header />
    <left-sidebar />
    <div class="content-page">
      <div class="container">
        <div class="row justify-content-center">
          <div class="d-none d-lg-block col-lg-3">
            <div style="top:90px;" class="card bg-black position-sticky shadow-sm">
              <div class="card-body">
                <side-bar-menu />
              </div>
            </div>
          </div>
          <div class="col">
            <router-view />
          </div>
        </div>
      </div>
    </div>
    <app-footer />
  </div>
</div>
</template>

<script>

import AppHeader from '@/components/app/AppHeader.vue'
import LeftSidebar from '@/components/app/LeftSidebar.vue'
import AppFooter from '@/components/app/AppFooter.vue'
import SideBarMenu from '@/components/app/components/SideBarMenu.vue'

export default {
  components: {
    AppHeader,
    LeftSidebar,
    AppFooter,
    SideBarMenu
  },
  computed: {
    layout()  { return this.$store.state.layout },
  },
  created() {
    this.$store.dispatch("orderList/fetchOrders")
  },
}
</script>

<style type="text/css">
  .content-page{
      min-height: 80vh;
      padding: 90px 0px 100px 0px;
    }
  .app-busy-mood{
    background: rgb(237 229 231);
    position: fixed;
    top: 0;
    z-index: 99999;
    opacity:0.4;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .font-sm{
    font-size:13px;
  }
  .cursor-pointer{
    cursor: pointer !important;
  }

  .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    background-color: #f2f3f5;
  }

</style>
